.main-container{
    min-height: 100vh;
    max-width: 100vw;
}

.dev-text-container{
    text-align: center;
  }
.dev-text{
    font-size: 18vw;
    font-weight: 600;
    color: white;
    text-shadow: 10px 5px 25px #e7e7e775;
}
@media (max-width: 1300px) {
    .dev-text-container{
        margin-top: 12px;
    }
}
@media (max-width: 1050px) {
    .dev-text-container{
        margin-top: 20px;
    }
  }
@media (max-width: 950px) {
    .dev-text-container{
      margin-top: 40px;
    }
  }
@media (max-width: 810px) {
    .dev-text-container{
        display: none;
    }
}


