.projects-container{
    width: 80%;
    margin: auto;
    padding: 120px 0 0;
}
.grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 70px;
    margin-top: 5rem;
}
.grid{
    position: relative;
    width: 100%;
    height: 50rem;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
}
.grid .project-detail{
    position: absolute;
    padding: 60px;
    background-color: transparent;
    transition: 0.3s ease;
}
.grid .project-detail h3{
    background-color: transparent;
    font-size: 2.5rem;
    transition: 0.3s ease;
}
/* Overlay */
.grid::before{
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    opacity: 0;
    transition: 0.3s ease;
}
.grid:hover::before{
  opacity: 0.85;
}
.grid:hover{
    color: white !important;
}
.hidden-detail,
.project-link,
.project-detail span{
    color: var(--white);
    opacity: 0;
    background: transparent;
    transition: 0.3s ease;
}
.project-link,
.project-detail span{
    font-weight: 300;
    margin-right: 5px;
}
.grid:hover .hidden-detail,
.grid:hover .project-link,
.grid:hover span{
    opacity: 1;
    margin-top: 2rem;
}

/* Links */
.grid:hover .project-link,
.grid:hover .project-link:visited{
    color: var(--gray);
    cursor: pointer;
}
.hidden-detail p,
.hidden-detail ul,
.hidden-detail .skills li,
.hidden-detail .responsibilities li,
.hidden-detail legend{
    color: var(--white);
    background: transparent;
}
.hidden-detail p{
    color: var(--gray);
}
.hidden-detail legend{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 25px 0 10px;
}
.hidden-detail .responsibilities li,
.hidden-detail .skills li{
    color: var(--gray);
    padding: 0.1rem;
}

.hidden-detail .responsibilities li{
    font-size: 22px;
    font-weight: 300;
}

/*Responsiveness*/
@media (max-width: 1800px) {
    .grid{
        height: 45rem;
    }
    .grid-container{
        gap: 60px;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend{
        font-size: 18px;
    }
}
@media (max-width: 1600px) {
    .grid{
        height: 40rem;
    }
    .grid-container{
        gap: 50px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
    }
}
@media (max-width: 1400px) {
    .grid{
        height: 35rem;
    }
    .grid-container{
        gap: 40px;
    }
    .grid:hover .project-detail h3{
        font-size: 24px !important;
    }
    .project-detail a,
    .hidden-detail li{
        font-size: 14px;
    }
    .grid .project-detail{
        padding: 10px 40px;
    }
    .grid:hover .project-detail{
        padding: 30px 40px;
    }
}
@media (max-width: 1200px) {
    .grid{
        height: 30rem;
    }
    .grid-container{
        gap: 5px;
        margin-top: 3rem;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend{
        font-size: 16px;
    }
    .grid .project-detail{
        padding: 0 40px;
    }
    .grid:hover .project-detail{
        padding: 30px 30px;
    }
}
@media (max-width: 1178px) {
    .grid:hover .hidden-detail,
    .hidden-detail legend{
        margin-top: 1rem;
    }
}
@media (max-width: 1072px) {
    .grid:hover .project-detail{
        padding: 15px;
    }
}
@media (max-width: 930px) {
    .grid-container{
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        margin-top: 2rem;
    }
    .grid{
        height: 45rem;
    }
    .grid .project-detail{
        padding: 60px;
    }
    .grid:hover .project-detail{
        padding: 60px;
    }
    .grid:hover .project-detail h3{
        font-size: 40px !important;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend{
        font-size: 22px;
    }
    .project-detail a,
    .hidden-detail .skills li{
        font-size: 16px;
    }
    ul{
        display: block;
    }
}
@media (max-width: 854px) {
    .projects-container{
        padding: 20px 0 0;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend{
        font-size: 20px;
    }
}
@media (max-width: 730px) {
    .grid{
        height: 40rem;
    }
    ul{
        display: flex;
    }
}
@media (max-width: 660px) {
    .grid{
        height: 35rem;
    }
    .grid:hover .project-detail h3{
        font-size: 24px !important;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend{
        font-size: 18px;
    }
    .project-detail a,
    .hidden-detail .skills li{
        font-size: 14px;
    }
    .grid .project-detail{
        padding: 10px 40px;
    }
    .grid:hover .project-detail{
        padding: 30px 40px;
    }
}
@media (max-width: 540px) {
    .grid{
        height: 30rem;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend{
        font-size: 16px;
    }
    .grid .project-detail{
        padding: 0 40px;
    }
    .grid:hover .project-detail{
        padding: 30px 30px;
    }
}
@media (max-width: 473px) {
    .grid{
        height: 25rem;
    }
    .grid:hover .hidden-detail,
    .hidden-detail legend{
        margin-top: 1rem;
    }
    .grid:hover .project-detail{
        padding: 15px;
    }
    .projects-container{
        width: 95%;
    }
    .hidden-detail .responsibilities li,
    .project-detail p,
    .hidden-detail legend,
    .project-detail a{
        line-height: 1.5rem;
        font-size: 14px;
    }
    .hidden-detail .skills li{
        font-size: 12px;
    }
    .grid .project-detail h3{
        font-size: 30px !important;
    }
    .grid:hover .project-detail h3{
        font-size: 18px !important;
    }
    .grid:hover .project-detail{
        padding: 20px;
    }
    .grid .project-detail{
        padding: 0 20px;
    }
    .grid:hover .hidden-detail,
    .hidden-detail legend{
        margin-top: .5rem;
    }
}