.contact-container{
    width: 80%;
    margin: auto;
    padding: 120px 0 80px;
}
.contact-form form{
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 100%;
    margin: auto;
    margin-top: 4rem;
}
.contact-form form input,
.contact-form form textarea{
    height: 3rem;
    outline: transparent;
    border: 2.5px solid var(--black);
    width: 50%;
    padding: 0.2rem 1rem;
    margin: auto;
    font-size: 1.5rem;
}
.contact-form form textarea{
    resize: none;
    height: 10rem;
    padding: .4rem 1rem;
}
.contact-form button{
    width: 150px;
    height: 50px;
    margin: auto;
    font-size: 1rem;
    font-weight: 500;
    border: 2.5px solid var(--black);
    transition: 0.3s ease;
    color: var(--black);
} 
.contact-form button:hover{
    background-color: var(--black);
    color: var(--white);
} 

/* Responsiveness */
@media (max-width: 1000px) {
    .contact-form form input,
    .contact-form form textarea{
        width: 70%;
    }
}
@media (max-width: 810px) {
    .contact-container{
        padding-top: 20px;
    }
    .contact-form form{
        margin-top: 2rem;
    }
}
@media (max-width: 500px) {
    .contact-form form input,
    .contact-form form textarea{
        width: 90%;
    }
}