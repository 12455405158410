.navbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    top: 0;
    font-size: 16px;
    width: 100%;
    height: 70px;
    position: fixed;
    transition: all 0.5s ease;
    z-index: 999;
}
.navbar-container .right,
.navbar-container .left,
.burger-menu{
    margin: 0 50px;
    background-color: transparent;
    transition: all 0.5s ease;
}
.navbar-container .left>h3,
.navbar-container .left>h2,
.navbar-container .right>a{
    color: var(--black);
    background-color: transparent;
    transition: all 0.5s ease;
}
.navbar-container .right>a{
    margin-left: 30px;
}
.navbar-container.scrolled{
    background-color: var(--black);
}
.navbar-container.scrolled .right>a,
.navbar-container.scrolled .left>h2,
.navbar-container.scrolled .left>h3{
    color: var(--white);
}
.navbar-container .left>h2{
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.navbar-container .left>h3{
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.left{
    display: flex;
    gap: 15px;
    align-items: center;
}

/* Menu dropdown */
.burger-menu{
    transform: scale(1.5);
    display: none;
}
.burger-menu .fas{
    background: transparent;
    color: var(--black);
    transition: color 0.5s ease;
}
.scrolled .burger-menu .fas{
    color: var(--white);
}
.burger-menu .options{
    display: none;
}
.burger-menu .options a{
    background: var(--black);
    cursor: pointer;
    padding: 10px;
    color: var(--white);
    font-size: 12px;
}
.burger-menu:hover .options{
    display: flex;
    flex-direction: column;
    width: 120px;
    left: -88px;
    position: absolute;
    background: var(--black);
    text-align: center;
}

/* Responsiveness */
@media (max-width: 925px) {
    .right{
      display: none;
    }
    .burger-menu{
        display: block;
    }
    .navbar-container .right,
    .navbar-container .left,
    .burger-menu{
        margin: 0 30px;
    }
    .navbar-container{
        height: 60px;
    }
}
@media (max-width: 480px) {
    .navbar-container .left>h3{
        text-indent:-9999px
    }
}
@media (max-width: 370px) {
    .navbar-container .left>h2{
        font-size: 20px;
    }
    .burger-menu{
        transform: scale(1.2);
    }
    .navbar-container .right,
    .navbar-container .left,
    .burger-menu{
        margin: 0 20px;
    }
    .navbar-container{
        height: 50px;
    }
}