.about-container{
    width: 80%;
    margin: auto;
    padding: 120px 0 0;
}
.about-text{
    margin: 3rem 0;
    line-height: 2rem;
}
/* Skills */
.skills-grid-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin: auto;
}
@media (max-width: 1500px) {
    .skills-grid-container{
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}
@media (max-width: 1200px) {
    .about-text{
        margin-top: 3rem;
    }
}
@media (max-width: 810px) {
    .about-container{
        padding: 20px 0 0;
    }
    .skills-grid-container{
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}