.employment-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 3rem;
}

.employment-data-container {
    display: flex;
    justify-content: space-between;
}

.employment-data-container, p {
    color: var(--black);
    font-size: 22px;
}

.responsiblities {
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--gray-dark);
}