.skill-container{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.skill-item{
    display: flex;
    flex-direction: column;
    gap: .3rem;
}
.skill-container h3{
    font-size: 2.5rem;
}
.skill-item h5{
    color: var(--gray-dark);
    font-size: 1.25rem;
}
@media (max-width: 540px) {
    .skill-container h3{
      font-size: 30px;
    }
}
@media (max-width: 320px) {
    .skill-container h3{
        font-size: 24px;
    }
}